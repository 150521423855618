var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"value":_vm.compareOrdersByDateModal,"persistent":"","fullscreen":""}},[_c('v-card',[_c('v-card-text',[_c('div',{staticClass:"small-text-field"},[_c('v-select',{attrs:{"outlined":"","filled":"","clearable":"","label":"Route","placeholder":"Kies een route","items":_vm.routes,"prepend-inner-icon":"mdi-routes","item-text":"route","menu-props":{
                  closeOnClick: true,
                  closeOnContentClick: false,
                  disableKeys: true,
                  openOnClick: false,
                  maxHeight: 304
                },"hide-details":""},on:{"click":function($event){_vm.selectedRoute=''}},scopedSlots:_vm._u([{key:"item",fn:function(ref){
                var item = ref.item;
return [_c('option',{domProps:{"value":item.route}},[_vm._v(" "+_vm._s(item.route)+" ["+_vm._s(item.length)+"] ")])]}}]),model:{value:(_vm.selectedRoute),callback:function ($$v) {_vm.selectedRoute=$$v},expression:"selectedRoute"}})],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.missingClients}})],1),_c('v-card-actions',[_c('v-footer',{staticClass:"d-flex justify-center",attrs:{"fixed":""}},[_c('v-btn-toggle',[_c('v-btn',{attrs:{"color":"primary","tile":""},on:{"click":_vm.closeCompareOrdersByDateModal}},[_vm._v("Sluit")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }