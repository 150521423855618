<template>
  <v-dialog :value="compareOrdersByDateModal" persistent fullscreen>
    <v-card>
      <v-card-text>
        <div class="small-text-field">
       <v-select
                  outlined
                  filled
                  clearable
                  label="Route"
                  placeholder="Kies een route"
                  :items="routes"
                  v-model="selectedRoute"
                  @click="selectedRoute=''"
                  prepend-inner-icon="mdi-routes"
                  item-text="route"
                  :menu-props="{
                    closeOnClick: true,
                    closeOnContentClick: false,
                    disableKeys: true,
                    openOnClick: false,
                    maxHeight: 304
                  }"
                  hide-details
                >
                  <template v-slot:item="{ item }">
                    <option :value="item.route">
                      {{ item.route }}
                      [{{ item.length }}]
                    </option>
                  </template>
                </v-select>
                </div>
        <v-data-table :headers="headers" :items="missingClients"></v-data-table>
      </v-card-text>
      <v-card-actions>
      <v-footer fixed class="d-flex justify-center">
        <v-btn-toggle> 
      <v-btn color="primary" tile @click="closeCompareOrdersByDateModal">Sluit</v-btn>
      </v-btn-toggle>
    </v-footer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { fetchGET } from "../js/functions"
export default {
  name: "compareOrdersByDateModal",
  props: { compareOrdersByDateModal: Boolean, orders: Array },
  data() {
    return {
      headers: [
        { text: "Naam", value: "name" },
        { text: "Telefoon", value: "phone" },
        { text: "Mobiel", value: "mobile" }
      ],
      targetClients: [],
      selectedRoute: "",
    }
  },
  methods: {
    closeCompareOrdersByDateModal() {
      this.$emit("close-compare-orders-by-date-modal")
    }
  },
  computed: {
    missingClients() {
     let missingClients = this.targetClients.filter(order=>{
      if(this.selectedRoute) return order.route == this.selectedRoute
      else return order
     }).reduce((acc, cur) => {
      if (
        this.orders.findIndex(
          order => order.clientNumber == cur.clientNumber
        ) == -1
      )
        acc.push(cur)
      return acc
    }, [])
    return missingClients
    },
    routes() {
      let map = this.missingClients.map(order => order.route)
      let set = new Set(map.sort())
      let routes = []
      set.forEach(route => {
        let obj = {
          route: route,
          length: this.missingClients.filter(order => order.route == route).length
        }
        routes.push(obj)
      })
      return routes
    },
  },
  async mounted() {
    let days = 7
    let d = new Date(new Date().toISOString().split("T")[0])
    let date = new Date(new Date().toISOString().split("T")[0])
    let compareDate = new Date(d.setDate(date.getDate() - days))
    let day = compareDate.getDate()
    let month = compareDate.getMonth()+1
    let year = compareDate.getFullYear()
    this.targetClients = await fetchGET("compareOrdersByDate", {
      day: day,
      month: month,
      year: year
    })
  }
}
</script>

<style scoped>
.small-text-field {
  width: 33%;
  min-height: 70px;
}
</style>