<template>
  <v-dialog :value="orderModal" fullscreen persistent>
    <v-dialog
      @click:outside="closeSearchItemDialog"
      :value="searchItemDialog"
      hide-overlay
      width="500px"
    >
      <v-card height="300px">
        <search-item
          v-if="searchItemDialog"
          :searchString="searchString"
          :autoFocus="true"
          @setSearchString="setSearchString"
          @get-results="addOrderItem"
          @clear-input="clearItem"
        ></search-item>
      </v-card>
    </v-dialog>
    <v-card style="margin-bottom: 120px !important">
      <v-card-text>
        <v-row no-gutters>
          <v-col class="d-flex" cols="12" sm="4">
            <div class="client-info mt-2">
              <fieldset class="fieldset text-left">
                <legend class="legend">Klant</legend>
                <div class="h1">
                  {{ thisOrder.name }}
                  <v-icon
                    class="edit-client-icon"
                    @click="openEditClientModal"
                    color="primary"
                    large
                    >mdi-pencil-circle</v-icon
                  >
                </div>
                <div class="subtitle-1">
                  <template v-if="thisOrder.client.shipping">
                    {{ thisOrder.client.shippingAddress }} <br />
                    {{ thisOrder.client.shippingPostalcode }},
                    {{ thisOrder.client.shippingCity }} <br />
                    {{ thisOrder.client.shippingCountry }}
                  </template>
                  <template v-else>
                    {{ thisOrder.client.address }} <br />
                    {{ thisOrder.client.postalcode }},
                    {{ thisOrder.client.city }} <br />
                    {{ thisOrder.client.country }}
                  </template>
                  <br />
                  {{ thisOrder.client.phone }} <br />
                  {{ thisOrder.client.mobile }}
                </div>
              </fieldset>
            </div>
          </v-col>
          <v-col cols="12" sm="8">
            <v-row no-gutters class="mt-5">
              <v-col sm="4">
                <div>
                  <v-btn
                    :disabled="!selectedItems.length"
                    @click="creditOrderItems"
                    color="primary"
                    >Crediteer</v-btn
                  >
                </div>
                <div>Kratten geleverd: {{ totalCrates }}</div>
                <div class="small-text-field">
                  <v-text-field
                    label="Kratten retour"
                    outlined
                    type="number"
                    v-model.number="thisOrder.returnedCrates"
                    @focus="$event.target.select()"
                    hide-details
                  ></v-text-field>
                  <v-btn
                    class="mt-5"
                    text
                    :disabled="cratesBalance == 0"
                    @click="copyCratesBalance"
                    color="primary"
                    >Krattensaldo naar <br />
                    nieuwe order</v-btn
                  >
                </div>
              </v-col>
              <v-col cols="12" sm="8" class="d-flex justify-space-around">
                <div class="small-text-field">
                  <v-menu
                    ref="datePicker"
                    v-model="datePicker"
                    :close-on-content-click="false"
                    :return-value.sync="date"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        filled
                        outlined
                        hide-details
                        v-model="dateFormatted"
                        @blur="date = parseDate(dateFormatted)"
                        label="Leverdatum"
                        prepend-inner-icon="mdi-calendar"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="date"
                      no-title
                      scrollable
                      locale="nl"
                    >
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="datePicker = false"
                        >Annl.</v-btn
                      >
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.datePicker.save(date)"
                        >OK</v-btn
                      >
                    </v-date-picker>
                  </v-menu>
                </div>
                <div class="small-text-field">
                  <v-menu
                    ref="menu"
                    v-model="timeMenu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    :return-value.sync="thisOrder.deliveryTime"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        filled
                        outlined
                        v-model="thisOrder.deliveryTime"
                        label="Levertijd"
                        prepend-inner-icon="mdi-clock-time-four-outline"
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-time-picker
                      v-if="timeMenu"
                      v-model="thisOrder.deliveryTime"
                      full-width
                      format="24hr"
                      @click:minute="$refs.menu.save(thisOrder.deliveryTime)"
                    ></v-time-picker>
                  </v-menu>
                </div>
                <div class="small-text-field">
                  <v-select
                    filled
                    outlined
                    prepend-inner-icon="mdi-routes"
                    :items="routes"
                    v-model="thisOrder.route"
                    label="Route"
                  ></v-select>
                </div>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col sm="4"></v-col>
              <v-col cols="12" sm="8" class="d-flex justify-space-around">
                <div class="small-text-field">
                  <fieldset class="fieldset">
                    <legend class="legend">Ordernr</legend>
                    {{ thisOrder.orderNumber }}
                  </fieldset>
                </div>
                <div class="small-text-field">
                  <fieldset class="fieldset">
                    <legend class="legend">Factuurnr</legend>
                    {{ thisOrder.invoiceNumber }}
                  </fieldset>
                </div>
                <div class="small-text-field">
                  <fieldset class="fieldset">
                    <legend class="legend">Status</legend>
                    <v-chip
                      class="fieldset-status"
                      @click="incOrderStatus"
                      :color="orderStatuses[thisOrder.status].color"
                      >{{ orderStatuses[thisOrder.status].text }}</v-chip
                    >
                  </fieldset>
                </div>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-text>
        <v-data-table
          dense
          :headers="itemHeaders"
          class="elevation-1 data-table"
          locale="nl"
          :items="thisOrder.items"
          hide-default-footer
          disable-pagination
          show-select
          v-model="selectedItems"
          item-key="_id"
        >
          <template v-slot:[`item.status`]="{ item }">
            <v-chip
              label
              @click="incItemStatus(item)"
              :color="itemStatuses[item.status].color"
              >{{ itemStatuses[item.status].text }}</v-chip
            >
          </template>
          <template v-slot:[`item.name`]="props">
            <v-edit-dialog
              :return-value.sync="props.item.name"
              @save="saveItem"
              @open="openItem"
              @cancel="cancelItem"
              @close="closeItem"
            >
              {{ props.item.name }}
              <template v-slot:input>
                <v-text-field
                  filled
                  v-model="props.item.name"
                  @change="updateOrderItem(props.item)"
                  label="Naam"
                ></v-text-field>
              </template>
            </v-edit-dialog>
          </template>
          <template v-slot:[`item.description`]="props">
            <v-edit-dialog
              :return-value.sync="props.item.description"
              large
              persistent
              cancel-text="Annuleren"
              save-text="Opslaan"
              @save="saveItem"
              @open="cachedDescription = props.item.description"
              @cancel="props.item.description = cachedDescription"
              @close="cachedDescription = ''"
            >
              <span v-html="props.item.description"></span>
              <template v-slot:input>
                <div @keydown.enter.stop>
                  <vue-editor v-model="props.item.description"></vue-editor>
                </div>
              </template>
            </v-edit-dialog>
          </template>
          <vue-editor></vue-editor>
          <template v-slot:[`item.amount`]="props">
            <v-edit-dialog
              @save="saveItem"
              @open="openItem"
              @cancel="cancelItem"
              @close="closeItem"
            >
              {{ props.item.amount }}
              <template v-slot:input>
                <v-text-field
                  filled
                  v-model="props.item.amount"
                  @focus="$event.target.select()"
                  @change="updateOrderItem(props.item)"
                  label="Aantal"
                ></v-text-field>
              </template>
            </v-edit-dialog>
          </template>
          <template v-slot:[`item.unitAmount`]="props">
            <v-edit-dialog
              :return-value.sync="props.item.unitAmount"
              @save="saveItem"
              @open="openItem"
              @cancel="cancelItem"
              @close="closeItem"
            >
              {{ props.item.unitAmount }}
              <template v-slot:input>
                <v-text-field
                  filled
                  v-model="props.item.unitAmount"
                  @focus="$event.target.select()"
                  @change="updateOrderItem(props.item)"
                  label="Inhoud"
                ></v-text-field>
              </template>
            </v-edit-dialog>
          </template>
          <template v-slot:[`item.packaging`]="props">
            <v-select
              :items="[
                { name: 'Krat', type: 'Crate' },
                { name: 'Doos', type: 'Box' },
              ]"
              item-text="name"
              item-value="type"
              v-model="props.item.packaging"
              @change="updateOrderItem(props.item)"
            ></v-select>
          </template>
          <template v-slot:[`item.crates`]="props">
            <v-edit-dialog
              :return-value.sync="props.item.crates"
              @save="saveItem"
              @open="openItem"
              @cancel="cancelItem"
              @close="closeItem"
            >
              {{ props.item.crates }}
              <template v-slot:input>
                <v-text-field
                  filled
                  v-model="props.item.crates"
                  @focus="$event.target.select()"
                  @change="
                    updateOrderItem(props.item, false, props.item.crates)
                  "
                  label="Kratten"
                ></v-text-field>
              </template>
            </v-edit-dialog>
          </template>
          <template v-slot:[`item.price`]="props">
            <v-edit-dialog
              :return-value.sync="props.item.price"
              @save="saveItem"
              @open="openItem"
              @cancel="cancelItem"
              @close="closeItem"
            >
              {{ toCurrency(props.item.price) }}
              <template v-slot:input>
                <v-text-field
                  filled
                  v-model="props.item.price"
                  @focus="$event.target.select()"
                  @change="updateOrderItem(props.item)"
                  label="Prijs"
                ></v-text-field>
              </template>
            </v-edit-dialog>
          </template>
          <template v-slot:[`item.VAT`]="{ item }"> {{ item.VAT }}% </template>
          <template v-slot:[`item.netSubtotal`]="{ item }">{{
            toCurrency(item.netSubtotal)
          }}</template>
          <template v-slot:[`item.grossSubtotal`]="{ item }">{{
            toCurrency(item.grossSubtotal)
          }}</template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon small @click="deleteOrderItem(item)">mdi-delete</v-icon>
            <!-- <v-icon @click="updateOrderItem(item, true)"
                >mdi-do-not-disturb--outofstock</v-icon TODO: add this icon
              > -->
          </template>
        </v-data-table>
        <v-row class="mt-1" no-gutters justify="center">
          <div>
            <v-btn @click="openSearchItemDialog" color="primary">
              <h1>+</h1>
            </v-btn>
          </div>
        </v-row>
      </v-card-text>
      <v-card-text>
        <v-row no-gutters>
          <v-col cols="12" sm="4">
            <table>
              <tr>
                <td>Aantal:</td>
                <td>
                  {{ thisOrder.items.length }}
                </td>
              </tr>
              <tr>
                <td>Aangemaakt:&nbsp;</td>
                <td>
                  {{ toDateTimeString(thisOrder.created) }}
                </td>
              </tr>
            </table>
          </v-col>
          <v-col cols="12" sm="4"></v-col>
          <v-col cols="12" sm="4"></v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="12" sm="4" class="pr-5">
            <v-textarea
              class="pt-2"
              label="Opmerkingen"
              outlined
              filled
              v-model="thisOrder.notes"
            ></v-textarea>
          </v-col>
          <v-col cols="12" sm="4">
            <fieldset class="fieldset" v-if="thisOrder.request">
              <legend class="legend">Offerteaanvraag</legend>
              <textarea v-model="thisOrder.request"></textarea>
            </fieldset>
          </v-col>
          <v-col cols="12" sm="4" class="d-flex justify-space-around flex-wrap">
            <div class="small-text-field">
              <fieldset class="fieldset">
                <legend class="legend">Totaal excl.</legend>
                {{ toCurrency(orderNetTotal) }}
              </fieldset>
            </div>
            <div class="small-text-field">
              <fieldset class="fieldset">
                <legend class="legend">Totaal incl.</legend>
                {{ toCurrency(orderGrossTotal) }}
              </fieldset>
            </div>
            <div class="small-text-field">
              <fieldset class="fieldset">
                <legend class="legend">Openstaand</legend>
                {{ toCurrency(orderDebit) }}
              </fieldset>
            </div>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-footer fixed height="120">
          <v-row align="end" no-gutters>
            <v-col cols="12" sm="2"></v-col>
            <v-col cols="12" sm="7" class="d-flex justify-center">
              <v-btn-toggle class="d-flex flex-wrap">
                <v-btn
                  @click="openPrintOrderModal"
                  class="d-none d-sm-flex"
                  color="primary"
                  >Afdrukken</v-btn
                >
                <v-btn
                  @click="openEmailOrderModal"
                  class="d-none d-sm-flex"
                  color="primary"
                  >Mailen</v-btn
                >
                <v-btn
                  class="d-none d-sm-flex"
                  color="primary"
                  @click="orderPaymentModal = true"
                  >Betaling</v-btn
                >
                <v-btn
                  class="d-none d-sm-flex"
                  color="primary"
                  @click="openClientModal"
                  >Rekening</v-btn
                >
                <v-btn
                  color="primary"
                  :disabled="cratesBalance != 0"
                  @click="deleteOrder(thisOrder)"
                  >Verwijder</v-btn
                >
                <v-btn color="primary" @click="closeOrderModal">Annuleer</v-btn>
                <v-btn color="primary" @click="updateOrder">Opslaan</v-btn>
              </v-btn-toggle>

              {{ cratesBalance }}
            </v-col>
            <v-col cols="12" sm="3" class="d-flex align-start justify-center">
              <fieldset class="fieldset">
                <legend class="legend">Totaal incl.</legend>
                {{ toCurrency(orderGrossTotal) }}
              </fieldset>
            </v-col>
          </v-row>
        </v-footer>
      </v-card-actions>
    </v-card>
    <client-modal
      v-if="clientModal"
      :clientModal="clientModal"
      :client="client"
      :tabNumber="3"
      @insert-client="insertClient"
      @delete-client="deleteClient"
      @close-client-modal="closeClientModal"
    ></client-modal>
    <order-payment-modal
      v-if="orderPaymentModal"
      :orderPaymentModal="orderPaymentModal"
      :order="order"
      @insert-payments="insertOrderPayments"
      @close-payment-modal="orderPaymentModal = false"
    ></order-payment-modal>
    <edit-client-modal
      v-if="editClientModal"
      :editClientModal="editClientModal"
      :client="client"
      :order="order"
      @update-client="updateClient"
      @close-edit-client-modal="closeEditClientModal"
    ></edit-client-modal>
  </v-dialog>
</template>
<script>
import orderPaymentModal from "./orderPaymentModal";
import {
  errorHandler,
  fetchPOST,
  getWeekNumber,
  successHandler,
  toCurrency,
  toDateTimeString,
} from "../js/functions.js";
import Big from "big.js";
import clientModal from "./clientModal";
import searchItem from "./searchItem";
import { VueEditor } from "vue2-editor";
import editClientModal from "@/components/editClientModal.vue";
export default {
  name: "orderModal",
  props: {
    order: Object,
    orderModal: Boolean,
  },
  data() {
    return {
      thisOrder: JSON.parse(JSON.stringify(this.order)),
      date: new Date(this.order.date).toISOString().split("T")[0],
      dateFormatted: this.formatDate(
        new Date(this.order.date).toISOString().split("T")[0]
      ),
      searchString: "",
      client: {},
      clientModal: false,
      orderPaymentModal: false,
      datePicker: false,
      searchItemDialog: false,
      itemHeaders: [
        { text: "Status", value: "status" },
        { text: "Naam", value: "name" },
        { text: "Omschrijving", value: "description" },
        { text: "Aantal", value: "amount" },
        { text: "Eenheid", value: "unit" },
        { text: "Inhoud", value: "unitAmount" },
        { text: "Verpakking", value: "packaging", width: 120 },
        { text: "Kratten", value: "crates" },
        { text: "Prijs", value: "price" },
        { text: "BTW", value: "VAT" },
        { text: "Subtotaal", value: "netSubtotal" },
        { text: "Totaal incl.", value: "grossSubtotal" },
        { text: "", value: "actions" },
      ],
      itemStatuses: [
        {
          status: 0,
          icon: "mdi-checkbox-blank-circle",
          color: "red",
          text: "In afwachting",
        },
        {
          status: 1,
          icon: "mdi-checkbox-blank-circle",
          color: "orange",
          text: "In behandeling",
        },
        {
          status: 2,
          icon: "mdi-checkbox-blank-circle",
          color: "light-green",
          text: "Voltooid",
        },
        {
          status: 3,
          icon: "mdi-checkbox-marked-circle",
          color: "orange",
          text: "Geselecteerd",
        },
        {
          status: 4,
          icon: "mdi-checkbox-marked-circle",
          color: "light-green",
          text: "Label afgedrukt",
        },
      ],
      orderStatuses: [
        {
          status: 0,
          icon: "mdi-checkbox-blank-circle",
          color: "red",
          text: "Nieuw",
          length: 0,
        },
        {
          status: 1,
          icon: "mdi-checkbox-blank-circle",
          color: "orange",
          text: "In behandeling",
          length: 0,
        },
        {
          status: 2,
          icon: "mdi-checkbox-blank-circle",
          color: "light-green",
          text: "Voltooid",
          length: 0,
        },
        {
          status: 3,
          icon: "mdi-checkbox-marked-circle",
          color: "blue",
          text: "Gefactureerd",
          length: 0,
        },
      ],
      totalHeaders: [{ text: "Ordertotaal", value: "grossTotal" }],
      payDate: new Date(Date.now()).toISOString().split("T")[0],
      payment: { amount: 0, payMethod: "", date: "" },
      timeMenu: false,
      editClientModal: false,
      cachedDescription: "",
      selectedItems: [],
    };
  },
  methods: {
    getWeekNumber(date) {
      return getWeekNumber(date);
    },
    changeToDot() {
      return 10;
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}-${month}-${year}`;
    },
    parseDate(date) {
      if (!date) return null;
      const [day, month, year] = date.split("-");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
    setSearchString(val) {
      this.searchString = val;
    },
    clearItem() {
      this.searchString = "";
    },
    toDateTimeString(val) {
      return toDateTimeString(val);
    },
    toCurrency(n) {
      return toCurrency(n);
    },
    insertOrderPayments(payments) {
      this.thisOrder.payments = payments;
      this.payments = payments;
      this.orderPaymentModal = false;
    },
    updateOrder() {
      let noZero = false;
      let confirmed = true;
      this.thisOrder.items.forEach((item) => {
        item.status = item.status < 3 ? this.thisOrder.status : item.status;
        if (item.status > -1) {
          if (item.amount == 0) noZero = true;
        }
      });
      if (noZero) {
        confirmed = confirm(
          "Er zijn nog artikelen die op 0 staan. Wilt u doorgaan?"
        );
      }
      if (confirmed) {
        let orderVAT;
        if (this.thisOrder.client.export) {
          orderVAT = [{ name: "Geen", amount: 0, total: this.orderNetTotal }];
        } else {
          orderVAT = JSON.parse(JSON.stringify(this.$store.state.settings.VAT));
          for (let VAT of orderVAT) {
            VAT.total = this.thisOrder.items
              .filter((item) => VAT.amount === item.VAT)
              .reduce((acc, cur) => {
                if (cur.VAT == 0)
                  return (acc * 100 + parseFloat(cur.netSubtotal) * 100) / 100;
                else return (acc * 100 + parseFloat(cur.totalVAT) * 100) / 100;
              }, 0);
          }
        }
        let VAT = orderVAT.filter((VAT) => parseFloat(VAT.total) != 0);
        this.thisOrder.VAT = VAT;
        this.thisOrder.totalVAT = this.thisOrder.items
          .filter((item) => item.VAT > 0)
          .reduce((acc, cur) => acc + cur.totalVAT, 0);
        let payments = this.thisOrder.payments;
        let totalPaid = payments.reduce((acc, cur) => acc + cur.amount, 0);
        let totalWeight = this.thisOrder.items.reduce(
          (acc, cur) => acc + cur.totalWeight,
          0
        );
        this.thisOrder.totalWeight = totalWeight;
        this.thisOrder.credit = totalPaid;
        this.thisOrder.debit = this.orderGrossTotal - totalPaid;
        let date = new Date(this.date);
        let isoDate = date.toISOString().split("T")[0];
        let day = date.getDate();
        let month = date.getMonth() + 1;
        let year = date.getFullYear();
        let week = this.getWeekNumber(date);
        let quarter = Math.ceil(month / 3);
        this.thisOrder.date = isoDate;
        this.thisOrder.day = day;
        this.thisOrder.month = month;
        this.thisOrder.year = year;
        this.thisOrder.week = week;
        this.thisOrder.quarter = quarter;
        this.thisOrder.totalProfit = this.totalProfit;
        this.thisOrder.netTotal = this.orderNetTotal;
        this.thisOrder.totalVAT = this.totalVAT;
        this.thisOrder.grossTotal = this.orderGrossTotal;
        this.thisOrder.totalPackages = this.totalPackages;
        this.thisOrder.totalCrates = this.totalCrates;
        this.thisOrder.returnedCrates = this.thisOrder.returnedCrates > 0 ? this.thisOrder.returnedCrates : 0;
        if (this.thisOrder.route == "Afhalen") {
          let confirmCrates = confirm(
            "Deze order wordt afgehaald. Wilt u de kratten meerekenen?"
          );
          if (!confirmCrates) {
            this.thisOrder.totalCrates = 0;
          }
        }
        this.$emit("update-order", this.thisOrder, false);
        this.closeOrderModal();
        return true;
      }
      return false;
    },
    insertClient() { },
    deleteClient() { },
    async openClientModal() {
      this.client = this.order.client;
      this.clientModal = true;
    },
    closeClientModal() {
      this.clientModal = false;
      this.client = {};
    },
    openSearchItemDialog() {
      this.searchItemDialog = true;
    },
    closeSearchItemDialog() {
      this.searchString = "";
      this.searchItemDialog = false;
    },
    commaToDot(val) {
      let string = val + "";
      let replace = string.replace(/,/g, ".");
      val = parseFloat(replace);
      return val;
    },
    addOrderItem(item) {
      // delete item._id
      let found = item.fixedPrices.find(
        (fixed) => fixed.clientNumber == this.thisOrder.clientNumber
      );
      if (found) {
        item.price = found.price;
      }
      let d = new Date(Date.now()).toISOString().split("T");
      d = d[0].split("-");
      let lotNumber = `${d[0].substr(-2)}/${d[1]}${d[2]}`;
      item.lotNumber = item.lotNumber || lotNumber;
      item.totalVAT = 0;
      this.thisOrder.items.push(item);
      this.closeSearchItemDialog();
    },
    updateOrderItem(item, outOfStock, forceCrates) {
      item.amount =
        typeof item.amount === "string"
          ? item.amount.replace(",", ".")
          : item.amount.toString().replace(",", ".");
      item.unitAmount =
        typeof item.unitAmount === "string"
          ? item.unitAmount.replace(",", ".")
          : item.unitAmount.toString().replace(",", ".");
      item.price =
        typeof item.price === "string"
          ? item.price.replace(",", ".")
          : item.price.toString().replace(",", ".");
      try {
        if (outOfStock) {
          item.amount = 0;
          item.status = 2;
        }
        item.amount = parseFloat(item.amount);
        item.unitAmount = parseFloat(item.unitAmount);
        item.price = parseFloat(item.price);
        let amount = Big(item.amount);
        let price = Big(item.price);
        let unitAmount = Big(item.unitAmount);
        let packages = Big();
        let crates = forceCrates ? forceCrates : item.crates;
        let totalWeight = Big();
        let netSubtotal = Big();
        let totalVAT = Big();
        let grossSubtotal = Big();
        item.VAT = this.thisOrder.client.export ? 0 : item.VAT;
        let itemVAT = Big(item.VAT).div(100);
        packages =
          item.unit == "kg"
            ? amount.div(item.packageAmount).round(undefined, 3)
            : amount;
        crates =
          item.packaging == "Crate" &&
            parseInt(item.unitAmount) > 5 && !forceCrates
            ? item.amount
            : crates;
        totalWeight =
          item.unit == "kg" ? amount : amount.times(item.unitAmount).round(2);
        netSubtotal =
          item.unit == "kg"
            ? amount.times(price).round(2)
            : amount.times(price).times(unitAmount).round(2);
        if (this.order.client.includeVAT)
          netSubtotal = netSubtotal.div(Big(item.VAT).plus(100).div(100));
        totalVAT = netSubtotal.times(itemVAT);
        grossSubtotal = netSubtotal.plus(totalVAT);
        item.packages = packages.toNumber();
        item.crates = crates;
        item.totalWeight = totalWeight.toNumber();
        item.netSubtotal = netSubtotal.toNumber();
        item.totalVAT = totalVAT.toNumber();
        item.grossSubtotal = grossSubtotal.toNumber();
        item.totalCost =
          item.unit == "kg"
            ? item.amount * item.cost
            : item.amount * item.unitAmount * item.cost;
        item.totalProfit = item.netSubtotal - item.totalCost;
      } catch (e) {
        console.error(e);
      }
    },
    saveItem() { },
    cancelItem() { },
    openItem() { },
    closeItem() { },
    deleteOrderItem(item) {
      for (let i = 0; i < this.thisOrder.items.length; i++) {
        if (
          this.thisOrder.items[i]._id === item._id &&
          this.thisOrder.items[i].name === item.name &&
          this.thisOrder.items[i].price === item.price &&
          this.thisOrder.items[i].amount === item.amount
        ) {
          this.thisOrder.items.splice(i, 1);
          return;
        }
      }
    },
    closeOrderModal() {
      this.$emit("close-order-modal");
    },
    incOrderStatus() {
      if (this.thisOrder.status < 3) {
        this.thisOrder.status =
          this.thisOrder.status < 2 ? this.thisOrder.status + 1 : 0;
      }
    },
    incItemStatus(item) {
      item.status = item.status < 4 ? item.status + 1 : 0;
    },
    deleteOrder(order) {
      this.$emit("delete-order", order);
    },
    openPrintOrderModal() {
      let confirmed = this.updateOrder();
      if (confirmed) this.$emit("open-print-order-modal", this.thisOrder);
    },
    openEmailOrderModal() {
      let confirmed = this.updateOrder();
      if (confirmed) this.$emit("open-email-order-modal", this.thisOrder);
    },
    openEditClientModal() {
      this.editClientModal = true;
      this.client = this.order.client;
    },
    closeEditClientModal() {
      this.editClientModal = false;
    },
    updateClient(client) {
      //TODO: update client only basic info
      this.thisOrder.client = client;
      this.thisOrder.clientNumber = client.clientNumber;
      this.thisOrder.name = client.name;
      this.closeEditClientModal();
    },
    async creditOrderItems() {
      try {
        let order = JSON.parse(JSON.stringify(this.thisOrder));
        delete order._id;
        delete order.invoiceNumber;
        order.status = 0;
        order.selectable = true;
        order.printed = false;
        order.emailed = false;
        order.items = this.selectedItems;
        for (let i = 0; i < order.items.length; i++) {
          order.items[i].amount = order.items[i].amount * -1;
          this.updateOrderItem(order.items[i]);
        }
        let res = await fetchPOST("insertOrder", order);
        order = res.ops[0];
        this.$store.commit("insertOrder", order);
        successHandler("Order gekopieerd");
        this.closeOrderModal();
      } catch (e) {
        errorHandler(e, "Order niet gekopieerd");
      }
    },
    async copyOrderItems() {
      try {
        let order = JSON.parse(JSON.stringify(this.thisOrder));
        delete order._id;
        delete order.invoiceNumber;
        order.status = 0;
        order.selectable = true;
        order.printed = false;
        order.emailed = false;
        order.items = this.selectedItems;
        for (let i = 0; i < order.items.length; i++) {
          this.updateOrderItem(order.items[i]);
        }
        let res = await fetchPOST("insertOrder", order);
        order = res.ops[0];
        this.$store.commit("insertOrder", order);
        successHandler("Order gekopieerd");
        this.closeOrderModal();
      } catch (e) {
        errorHandler(e, "Order niet gekopieerd");
      }
    },
    async copyCratesBalance() {
      try {
        let order = JSON.parse(JSON.stringify(this.thisOrder));
        delete order._id;
        delete order.invoiceNumber;
        order.status = 0;
        order.selectable = true;
        order.printed = false;
        order.emailed = false;
        order.returnedCrates = 0;
        order.totalCrates = this.cratesBalance
        order.netTotal = 0;
        order.totalVAT = 0;
        order.grossTotal = 0;
        order.totalPackages = 0;
        order.totalWeight = 0;
        order.credit = 0;
        order.debit = 0;
        order.payments = [];
        order.items = [
          {
            name: "Kratten",
            price: 0,
            unit: "x",
            unitAmount: "10",
            category: "Overig",
            amount: this.cratesBalance,
            copies: 0,
            sort: 0,
            VAT: 0,
            packaging: "Crate",
            itemNumber: -1,
            totalVAT: 0,
            grossSubtotal: 0,
            netSubtotal: 0,
            cost: 0,
            totalCost: 0,
            profit: 0,
            totalProfit: 0,
            status: 0,
            assigned: "",
            packageAmount: 0,
            packages: 0,
            inUnit: "x",
            inPrice: 0,
            fixed: [],
            reserved: 0,
            lotNumber: "",
            fixedPrices: [],
            stock: 0,
            crates: this.cratesBalance,
          },
        ];
        let res = await fetchPOST("insertOrder", order);
        order = res.ops[0];
        this.$store.commit("insertOrder", order);
        successHandler("Order gekopieerd");
        this.thisOrder.returnedCrates = this.thisOrder.totalCrates;
        this.updateOrder();
      } catch (e) {
        errorHandler(e, "Order niet gekopieerd");
      }
    },
  },
  computed: {
    items() {
      return this.$store.state.items;
    },
    routes() {
      return this.$store.state.settings.routes;
    },
    payMethods() {
      return this.$store.state.settings.payMethods;
    },
    totalProfit() {
      return this.thisOrder.items.reduce(
        (acc, cur) => (acc * 100 + parseFloat(cur.totalProfit) * 100) / 100,
        0
      );
    },
    orderNetTotal() {
      return this.thisOrder.items.reduce(
        (acc, cur) => (acc * 100 + parseFloat(cur.netSubtotal) * 100) / 100,
        0
      );
    },
    totalVAT() {
      return this.thisOrder.items.reduce(
        (acc, cur) => (acc * 100 + parseFloat(cur.totalVAT) * 100) / 100,
        0
      );
    },
    totalPackages() {
      return this.thisOrder.items.reduce(
        (acc, cur) => (acc * 100 + parseFloat(cur.packages) * 100) / 100,
        0
      );
    },
    totalCrates() {
      return this.thisOrder.items.reduce(
        (acc, cur) => (acc * 100 + parseFloat(cur.crates) * 100) / 100,
        0
      );
    },
    cratesBalance() {
      return this.totalCrates - this.thisOrder.returnedCrates;
    },
    orderGrossTotal() {
      return (
        (parseFloat(this.orderNetTotal) * 100 +
          parseFloat(this.totalVAT) * 100) /
        100
      );
    },
    orderCredit() {
      return this.thisOrder.payments.reduce(
        (acc, cur) => (acc * 100 + cur.amount * 100) / 100,
        0
      );
    },
    orderDebit() {
      return (this.orderGrossTotal * 100 - this.orderCredit * 100) / 100;
    },
    statuses() {
      return this.$store.state.settings.orderStatuses;
    },
  },
  watch: {
    date(val) {
      this.dateFormatted = this.formatDate(val);
    },
  },
  components: {
    searchItem,
    orderPaymentModal,
    clientModal,
    VueEditor,
    editClientModal,
  },
};
</script>
<style scoped>
.amount {
  width: 60px;
  text-align: right;
}
.crates {
  width: 25px;
  display: inline-block;
}
.inAmount {
  padding-right: 10px;
}
.status {
  height: 20px;
  vertical-align: top;
}
.small-text-field {
  width: 33%;
  min-height: 70px;
}
.fieldset {
  border: 1px solid #00000061;
  border-radius: 5px;
  text-align: center;
  font-weight: bold;
  width: 100%;
  min-height: 70px;
  padding: 5px;
  font-size: 1.2rem;
  background: #0000000f;
}
.fieldset textarea {
  width: 100%;
  height: 200px;
  border: none;
  resize: none;
  font-size: 1.2rem;
}
.fieldset-status {
  margin-top: -5px;
}
.legend {
  text-align: left;
  margin-left: 5px;
  padding-right: 3px;
  font-size: 13px;
  font-weight: 400;
}
.edit-client-icon {
  position: absolute;
  right: 0;
  top: 14px;
}
.client-info {
  position: relative;
}
.data-table {
  border: 1px solid rgba(0, 0, 0, 0.38);
  background: #0000000f;
}
::v-deep .v-text-field--filled:not(.v-text-field--single-line) input {
  margin: 0 !important;
}
</style>
