<template>
  <v-dialog
    :value="printTotalsModal"
    persistent
    fullscreen
    @keydown.enter="print"
  >
    <v-card>
      <v-card-title>Print totaallijst</v-card-title>
      <v-row>
        <v-col cols="6">
          <input
            class="hidden"
            id="input"
            @focus="closePrintTotalsModal"
            type="text"
          />
          <fieldset>
            <legend>Status</legend>
            <v-select
              v-model="selectedStatus"
              :items="statuses"
              item-text="name"
              item-value="value"
              @change="selectStatus"
              clearable
            ></v-select>
          </fieldset>
          <fieldset>
            <legend>Routes</legend>
            <div class="d-flex align-center">
              <v-checkbox
                :value="allRoutes"
                @change="selectAllRoutes"
                label="Alles selecteren"
              ></v-checkbox>
            </div>
            <div class="d-flex flex-wrap">
              <v-checkbox
                v-for="route of routes"
                :key="route"
                v-model="selectedRoutes"
                :label="route"
                :value="route"
                @change="selectRoute"
              ></v-checkbox>
            </div>
          </fieldset>
          <fieldset>
            <legend>Categorieën</legend>
            <div class="d-flex align-center">
              <v-checkbox
                :value="allCategories"
                @change="selectAllCategories"
                label="Alles selecteren"
              ></v-checkbox>
            </div>
            <div class="d-flex flex-wrap">
              <v-checkbox
                v-for="category of categories"
                :key="category"
                v-model="selectedCategories"
                :label="category"
                :value="category"
                @change="viewTotals"
              ></v-checkbox>
            </div>
          </fieldset>
          <template v-if="isElectron">              
              <label>Printer:</label
              ><select v-model="options.printer"
                ><option
                  v-for="printer of printers"
                  :key="printer.name"
                  :value="printer.name"
                >
                  {{ printer.displayName }}
                </option>
              </select>
              <label>Aantal:</label
              ><input type="number" v-model="options.copies" />
              <label>Formaat:</label
              ><select v-model="options.pageSize"
                ><option
                  v-for="pageSize of pageSizes"
                  :key="pageSize.value"
                  :value="pageSize.value"
                >
                  {{ pageSize.text }}
                </option>
              </select>
            </template>
        </v-col>
        <v-col cols="6">
          <iframe :src="dataUrl" frameborder="0" id="iframe"></iframe>
        </v-col>
      </v-row>
      <v-footer fixed height="50" class="d-flex justify-center">
        <v-btn-toggle>
          <v-btn @click="closePrintTotalsModal" color="primary">Sluit</v-btn>
          <v-btn color="primary" @click="print">Afdrukken</v-btn>
        </v-btn-toggle>
      </v-footer>
    </v-card>
  </v-dialog>
</template>
<script>
import { viewTotals,printTotals } from "../js/pdfController"
export default {
  name: "printTotalsModal",
  props: {
    printTotalsModal: Boolean
  },
  data() {
    return {
      dataUrl: "",
      buffer: "",
      iframeLoaded: false,
      pageSizes: [
        {value: "A4", text: "A4"},
        {value: "A5", text: "A5"},
      ],
      options: {
        pageSize: "A4",
        copies: 1,
        printer: "",
      },
      selectedStatus: null,
      selectedCategories: [],
      selectedRoutes: [],
      allRoutes: false,
      allCategories: false,
      statuses: [
        { name: "Alles", value: null },
        { name: "In afwachting", value: 0 },
        { name: "In behandeling", value: 1 },
        { name: "Voltooid", value: 2 },
        { name: "Gefactureerd", value: 3 }
      ]
    }
  },
  methods: {
    selectStatus() {
      this.selectedCategories = []
      this.allCategories = false
      this.allRoutes = false
      this.selectedRoutes = []
    },
    selectAllRoutes() {
      this.allRoutes = !this.allRoutes
      this.selectedRoutes = this.allRoutes ? Array.from(this.routes) : []
      this.selectedCategories = []
      this.allCategories = false
      this.viewTotals()
    },
    selectRoute() {
      this.selectedCategories = []
      this.allCategories = false
      this.viewTotals()
    },
    selectAllCategories() {
      this.allCategories = !this.allCategories
      this.selectedCategories = this.allCategories
        ? Array.from(this.categories)
        : []
      this.viewTotals()
    },
    iframeOnload() {
      let iframe = document.getElementById("iframe")
      iframe.removeEventListener("load", this.iframeOnload)
      if (
        iframe.contentDocument.readyState === "complete" &&
        this.iframeLoaded
      ) {
        this.printTotals()
      } else {
        var that = this
        setTimeout(function() {
          return that.iframeOnload(iframe)
        }, 1000)
      }
    },
    async print() {
      if (this.isElectron) {
        this.printTotals()
      } else {
      document
        .getElementById("iframe")
        .addEventListener("load", this.iframeOnload)
      await this.viewTotals()
      this.iframeLoaded = true
      }
    },
    async printTotals() {
      localStorage.setItem("printTotalsOptions", JSON.stringify(this.options))
      if (this.isElectron) {
        let options = {
          printer: this.options.printer,
          paperSize: this.options.pageSize,  
          copies: this.options.copies,
          scale: "fit",
          silent: true
        }
        this.buffer = await printTotals( this.totals,
        this.selectedRoutes.length,
        this.selectedCategories.length
      )
        window.ipcRenderer.send("printPdfBuffer", {
          buffer: this.buffer,
          options: options
        })
      } else document.getElementById("iframe").contentWindow.print()
      setTimeout(() => {
        let input = document.getElementById("input")
        input.focus()
      }, 1000)
    },
    closePrintTotalsModal() {
      URL.revokeObjectURL(this.dataUrl)
      this.$emit("close-print-totals-modal")
    },
    async viewTotals() {
      let data = await viewTotals(
        this.totals,
        this.selectedRoutes.length,
        this.selectedCategories.length
      )
      this.dataUrl = URL.createObjectURL(data)
    }
  },
  computed: {
    orders() {
      return this.$store.state.orders
    },
    routes() {
      let routes = new Set()
      for (let order of this.orders) {
        if (order.status == this.selectedStatus || this.selectedStatus == null)
          routes.add(order.route)
      }
      return Array.from(routes).sort()
    },
    categories() {
      let categories = new Set()
      for (let order of this.orders) {
        if (
          (this.selectedStatus == order.status ||
            this.selectedStatus == null) &&
          (this.selectedRoutes.includes(order.route) ||
            !this.selectedRoutes.length)
        )
          for (let item of order.items) {
            categories.add(item.category)
          }
      }
      return categories
    },
    totals() {
      let totals = {}
      let items = []
      let routes = this.selectedRoutes.length
      let categories = this.selectedCategories.length
      let orders = this.orders
      orders = orders.filter(order=>order.status===this.selectedStatus||this.selectedStatus===null)
      for (let order of orders) {
        for (let item of order.items) {
          item.route = order.route
          items.push(item)
      }
        items = items.sort((a,b)=>a.sort-b.sort)
      }
      for(let item of items){
          if (!routes && !categories) {
              totals[item.name] = totals[item.name] || {}
              totals[item.name].amount = totals[item.name].amount || 0
              totals[item.name].amount += item.amount
              totals[item.name].unit = item.unit
              totals[item.name].totalWeight = totals[item.name].totalWeight || 0
              totals[item.name].totalWeight += item.totalWeight
              totals[item.name].packages = totals[item.name].packages || 0
              totals[item.name].packages += item.packages
              totals[item.name].category = item.category
          } else if (
            !routes&&categories
          ) {
              if (this.selectedCategories.includes(item.category)) {
                totals[item.category] = totals[item.category] || {}
                totals[item.category][item.name] =
                  totals[item.category][item.name] || {}
                totals[item.category][item.name].amount =
                  totals[item.category][item.name].amount || 0
                totals[item.category][item.name].amount += item.amount
                totals[item.category][item.name].unit = item.unit
                totals[item.category][item.name].totalWeight = totals[item.category][item.name].totalWeight || 0
                totals[item.category][item.name].totalWeight += item.totalWeight
                totals[item.category][item.name].packages = totals[item.category][item.name].packages || 0
                totals[item.category][item.name].packages += item.packages
              }
          } else if (
           categories &&
            this.selectedRoutes.includes(item.route)
          ) {
              if (this.selectedCategories.includes(item.category)) {
              totals[item.route] = totals[item.route] || {}
              totals[item.route][item.category] =
                totals[item.route][item.category] || {}
              totals[item.route][item.category][item.name] =
                totals[item.route][item.category][item.name] || {}
              totals[item.route][item.category][item.name].amount =
                totals[item.route][item.category][item.name].amount || 0
              totals[item.route][item.category][item.name].amount +=
                item.amount
              totals[item.route][item.category][item.name].unit = item.unit
              totals[item.route][item.category][item.name].totalWeight = totals[item.route][item.category][item.name].totalWeight || 0
              totals[item.route][item.category][item.name].totalWeight +=
                item.totalWeight
                totals[item.route][item.category][item.name].packages = totals[item.route][item.category][item.name].packages || 0
              totals[item.route][item.category][item.name].packages +=
                item.packages
            }
          } else if (!categories&&this.selectedRoutes.includes(item.route)) {
              totals[item.route] = totals[item.route] || {}
              totals[item.route][item.name] =
                totals[item.route][item.name] || {}
              totals[item.route][item.name].amount =
                totals[item.route][item.name].amount || 0
              totals[item.route][item.name].amount += item.amount
              totals[item.route][item.name].unit = item.unit
              totals[item.route][item.name].totalWeight = totals[item.route][item.name].totalWeight || 0
              totals[item.route][item.name].totalWeight += item.totalWeight
              totals[item.route][item.name].packages = totals[item.route][item.name].packages || 0
              totals[item.route][item.name].packages += item.packages
              totals[item.route][item.name].category = item.category
          }
      }
      return totals
    },
    isElectron() {
      return this.$store.state.isElectron 
    },
    printers(){
      return this.$store.state.printers
    }
  },
  async mounted() {
    if (JSON.parse(localStorage.getItem("printTotalsOptions")) !== null) {
      let obj = JSON.parse(localStorage.getItem("printTotalsOptions"))
      this.options = obj
    }
    await this.viewTotals()
  }
}
</script>
<style scoped>
div.settings {
  justify-content: space-evenly;
  font-size: 2em;
  display: grid;
  grid-template-columns: max-content max-content;
  grid-gap: 5px;
}
input[type="checkbox"] {
  height: 1em;
  width: 1em;
}
div.settings label {
  text-align: right;
}
#iframe {
  height: 700px;
  width: 600px;
  margin: auto;
  padding: 0;
  display: block;
}
.hidden {
  width: 0;
  height: 0;
  overflow: hidden;
}
</style>